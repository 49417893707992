<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-row :gutter="8">
        <el-col :span="6">
          <el-col :span="6">地區</el-col>
          <el-col :span="16">
            <el-select v-model="qAreaId" width="120">
              <el-option 
                v-for="item in areas"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-col :span="6">店家</el-col>
          <el-col :span="16">
            <el-select v-model="qStoreId_" multiple width="120">
              <el-option 
                v-for="item in selectStores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                v-show="item.areaId == qAreaId || qAreaId == ''"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-col>
        <el-col :span="12">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-col :span="6">
        <el-button type="primary" @click="requestAnnualRevenueReportXls()">按月總門市營收報表</el-button>
        <el-button type="primary" @click="requestEffAutoCancelExcel()">產出自動取消分析報表</el-button>
        <el-button type="primary" @click="query()">重新整理</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="requestDateDis" label="產出日期" width="200px"> </el-table-column>
      <el-table-column prop="queryParam" label="查詢參數" width="300px"> </el-table-column>
      <el-table-column prop="fileName" label="檔名" width="290px"> </el-table-column>
      <el-table-column prop="reportTypeDis" label="報表種類" width="200px"> </el-table-column>
      <el-table-column prop="status" label="狀態" width="200px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.downStatus">
            {{scope.row.status}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <el-button @click="downReport(scope.row)" type="primary" size="small" v-show ="scope.row.downStatus === 'success'">下載</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      q: {
        qStoreId: '',
        qSDate: null,
        qEDate: null,
      },
      url: '',
      sDate: "",
      eDate: "",
      qAreaId: '',
      qStoreId_: '',
      areas: [],
      stores: [],
      storesMap: [],
      selectStores: [],
      tableData: [],
      reportTypeList:[{value: 'EffAutoCancelExcel', label: "自動取消分析"},],
      fullscreenLoading: false,
      downStatus : ''
    }
  },
  created() {
    this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.qAreaId = this.areas[0].code
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });
        this.query()
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  components: {
  },
  computed: {
  },
  methods: {
    query() {
      this.fullscreenLoading = true
      this.$axios
        .get('../api/reportExport/getReports', {
          params: {
            page: this.pageCurrent
          }
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(e => {
            this.tableData.push(e)
            e.requestDateDis = this.$date_(e.requestDate)
            this.reportTypeList.forEach(item => {
              e.reportTypeDis = item.value == e.reportType ? item.label : ''
            })
            if (e.status === 'SUCCESS') {
              e.downStatus = 'success'
              e.status = '成功'
            } else if (e.status === 'FAIL') {
              e.downStatus = 'danger'
              e.status = '失敗'
            } else if (e.status === 'WAIT') {
              e.downStatus = 'warning'
              e.status = '計算中'
            }
          })
          this.pageTotal = res.data.pageTotal
          this.fullscreenLoading = false
        }).catch(() => {
          this.$alert('不知名的錯誤', '結果', { confirmButtonText: '確定' })
          this.fullscreenLoading = false
        });
    },

    requestAnnualRevenueReportXls() {
      this.fullscreenLoading = true
      const sDate =  moment(this.sDate).format('YYYYMMDD')
      const eDate = moment(this.eDate).format('YYYYMMDD')
      this.$axios.post('../api/reportExport/downAnnualRevenueReportXls', {
        qSDate: sDate,
        qEDate: eDate
      }).then((res) => {
        this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
        this.fullscreenLoading = false
      }).catch(()=>{
        this.$alert('不知名的錯誤', '結果', { confirmButtonText: '確定' })
        this.fullscreenLoading = false
      });
    },

    requestEffAutoCancelExcel() {
      this.fullscreenLoading = true
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId = this.q.qStoreId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
      let start_date_moment = moment(this.sDate);
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qSDate = _qSdate;
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      this.$axios
        .post('../api/reportExport/exportEffAutoCancelExcel', this.q)
        .then((res) => {
          this.$alert(res.data.rsMsg)
          this.fullscreenLoading = false
        }).catch(() => {
          this.$alert('不知名的錯誤', '結果', { confirmButtonText: '確定' })
          this.fullscreenLoading = false
        });
    },
    downReport(data) {
      window.open('../api/reportExport/downloadReport?fileName='+data.fileName)
    }
  }
}
</script>

<style scoped>
</style>
