<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="Slack頻道名稱" v-model="qslackChannelName">
              <template slot="prepend">Slack頻道名稱</template>
            </el-input>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>
      </el-row>      
    </el-card>

    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column prop="rowId" label="ID" width="80"></el-table-column>
        <el-table-column prop="slackChannelName" label="Slack頻道名稱" width="200"></el-table-column>
        <el-table-column prop="alertChannel" label="已通知的門市頻道" width="250"  ></el-table-column>
        <el-table-column prop="adminEmail" label="管理者Email"> 
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <!-- <el-button  @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button> -->
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible"  >
  <el-form :model="form" ref="editForm" :rules="rules">
    <el-form-item label="ID" :label-width="formLabelWidth" prop="slackChannelName">
      <el-input v-model="form.rowId" autocomplete="off" readonly></el-input>
    </el-form-item>
    <el-form-item label="Slack頻道名稱" :label-width="formLabelWidth" prop="slackChannelName">
      <el-input v-model="form.slackChannelName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="已通知的門市頻道" :label-width="formLabelWidth" prop="alertChannel">
      <el-input v-model="form.alertChannel" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="管理者Email" :label-width="formLabelWidth" prop="adminEmail">
      <el-input v-model="form.adminEmail" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="updateEditForm" v-show="formButtonStatus.updateEditFormBtn">編輯</el-button>
    <el-button type="primary" @click="submitSlackNotifyForm" v-show="formButtonStatus.submitEditFormBtn">新 增</el-button>
  </div>
</el-dialog>

  </div>
</template>


<script>
export default {
  data () {
    return {
      formLabelWidth: '150px', 
      labelPosition: 'right',
      // ---------------------
      pageTotal:0,
      pageCurrent:1,
      dialogFormVisible: false,
      dialogAuthVisible: false,
      // ---------------------
      qslackChannelName:'',
      tableData:[], // 查詢後data
      form: { // 編輯
        rowId:'',
        slackChannelName: '',
        alertChannel: '',
        adminEmail: ''
      },
      formButtonStatus: {
        updateEditFormBtn: false,
        submitEditFormBtn: false
      }
      
    }
  },
  created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });
  },
  name: 'AdminStoreNotifySlack',
  components: {
  },
  computed:{
  },
  methods :{
    getFormParams() { // form欄位資料
      return {
        rowId: this.form.rowId,
        slackChannelName: this.form.slackChannelName,
        alertChannel: this.form.alertChannel,
        adminEmail: this.form.adminEmail
      }
    },
    query(){ // 查詢
      this.$axios
        .get('../api/adminStoreNotifySlack/findAllStoreNotifySlack', { 
          params: {
            qslackChannelName: this.qslackChannelName
          }
      }).then((res)=>{
        if(res.data.rs !== "success") {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' }) // 1.查無資料，尚未建立 2.查詢失敗，請通知管理員
          return
        }
        this.tableData = []
        res.data.data.forEach(e=>{
          this.tableData.push(e)
        })
      }).catch((res)=>{
          console.log(res)
      });
    },
    showDetailForm(formParams) { // 打開該行資料form
      this.dialogFormVisible = true
      this.formButtonStatus.updateEditFormBtn = true
      this.formButtonStatus.submitEditFormBtn = false
      this.$axios.get('../api/adminStoreNotifySlack/getStoreNotifySlackDetail', {
        params: { slackChannelName: formParams.slackChannelName }
      }).then((res) => {
        if (res.data.rs === 'success') {
          this.form = Object.assign({}, this.form, {
              rowId: formParams .rowId,
              slackChannelName: formParams .slackChannelName,
              alertChannel: formParams .alertChannel,
              adminEmail: formParams .adminEmail
            })
        } else {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
        }
      }).catch((res) => {
        this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定'})
      })
    },
    showCreateDetailForm() { // 打開新增資料form
      this.dialogFormVisible = true
      this.formButtonStatus.updateEditFormBtn = false
      this.formButtonStatus.submitEditFormBtn = true
      this.form = Object.assign({}, this.form, {
        slackChannelName:'',
        alertChannel:'', 
        adminEmail:''
      })
    },
    updateEditForm() {
      this.$axios.post('../api/adminStoreNotifySlack/updateStoreNotifySlack', this.getFormParams())
        .then(res => {
          if (res.data.rs === 'success') {
            this.$notify({
              title: '結果',
              message: '已成功更新',
              type: 'success',
              duration: 4500,
              position: 'top-right',
              showClose: true
            })
          this.query()
          this.dialogFormVisible = false
        } else {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
        }
      }).catch((res) => {
        console.error('更新失敗', res)
      })
    },
    submitSlackNotifyForm() { // 新增該行資料
      this.getFormParams()
      const { slackChannelName, alertChannel, adminEmail } = this.form
      if (slackChannelName === '') {
        this.$alert('Slack頻道名稱，不得為空', '結果', { confirmButtonText: '確定'})
        return
      } else if (alertChannel === '') {
        this.$alert('已通知的門市頻道，不得為空', '結果', { confirmButtonText: '確定'})
        return
      }  else if (adminEmail === '') {
        this.$alert('管理者Email，不得為空', '結果', { confirmButtonText: '確定'})
        return
      }
      this.$axios.post('../api/adminStoreNotifySlack/insertSlackNotify', this.getFormParams())
        .then(res => {
          if (res.data.rs === 'success') {
            this.$notify({
                  title: '結果',
                  message: '已成功修改',
                  type: 'success',
                  duration: 4500,
                  position: 'top-right',
                  showClose: true
                })
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定'})
          }
          this.dialogFormVisible = false
      })
    }
  }
}
</script>
